<template>
  <div>
    <hello-world/>
    <Sexperimenter/>
    <comic-show/>
  </div>
</template>

<script>
import HelloWorld from "../components/home/HelloWorld";
import NavigationMixin from "../mixins/NavigationMixin";
import ComicShow from "../components/home/ComicShow";
import Sexperimenter from "../components/home/Sexperimenter";

export default {
  name: "Home",
  mixins: [NavigationMixin],
  components: {
    Sexperimenter,
    ComicShow,
    HelloWorld
  },
  mounted() {
    this.setAvaibleNavs(['#home', '#canvas', '#comic-show']);
  },
};
</script>
