<template>
  <v-container style="height: 100vh; max-height: 100vh;" class="pa-0 private-background" fluid
               id="sexperimenter">
    <v-card :style="$vuetify.breakpoint.mobile?'top:10vh':'top:25vh; max-width: 60vw'" class="ml-2 ml-sm-5" flat color="transparent">
      <v-card-title>
        <h1 class="text-uppercase mb-6 mt-6">Offrir la possibilité à tous les couples de sexpérimenter à leur rythme</h1>
      </v-card-title>
      <v-card-text>
        <p>La mission de Private-Games est de permettre aux couples avec un grand <b>C</b> d’aborder leur sexpérimentation
          sereinement.
        </p>
        <p>À travers les nombreux défis et fonctionnalités de l’app, chaque couple pourra trouver son bonheur dans son
          épanouissement sexuel.</p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "OfferMore"
}
</script>

<style scoped lang="scss">
h1 {
  font-family: "Nunito";
  font-weight: bolder;
  @media (max-width: 758px) {
    font-size: 1.5em;
  }
}

.private-background {
  background-image: url("~@/assets/background/background-pg-xl-white.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f9e6df;
  height: calc(100vh - 128px);
  width: 100vw;
  min-width: 100vw;
  @media (max-width: 768px) {
    background-image: url("~@/assets/background/background-pg-portrait-xl-white.jpg");
  }
}
</style>