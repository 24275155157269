<template>
  <v-container class="private-background pa-0" id="comic-show">
    <div class="phylactere">
      <div class="comic-text">
        <v-carousel
            cycle
            height="300"

            hide-delimiter-background
            hide-delimiters
            :show-arrows="false"
            dark
            interval="5000"
        >
          <v-carousel-item
              v-for="(slide, i) in slides"
              :key="i"
          >
            <v-row class="fill-height" no-gutters align="center" justify="start">
              <div class="black--text" v-html="slide"/>
            </v-row>
          </v-carousel-item>
        </v-carousel>
      </div>
    </div>
    <v-btn class="ma-auto pg-hot-gradient beta-button" dark
           href="https://app.private-games.com"
           target="_blank">
      Jouer maintenant !
    </v-btn>
  </v-container>
</template>

<script>
export default {
  name: "ComicShow",
  data() {
    return {
      slides: [
        '<h2>Une invitation à vibrer avec les émotions, le jeu, les corps, les surprises... Grâce à la sexpérimentation à deux.</h2>',
        '<h2>Jouez en couple</h2><p>Scénarios érotiques, défis coquins, plus ou moins softs … En fonction de vos envies.</p>',
        '<h2>Des jeux pour couple(s)</h2><p>Une app inclusive, accessible à tous, et ... <span class="text-uppercase">gratuite</span>.</p>',
        '<h2>Écrivez vos propres jeux</h2><p>Votre imagination est la seule limite ! Partagez vos défis coquins avec votre partenaire … ou la communauté.</p>',
      ],
    }
  },
}
</script>

<style scoped lang="scss">
.beta-button{
  top: 80vh;
  left: 42vw;
  @media (max-width: 768px) {
    left: 21vw;
  }
}
.private-background {
  background-image: url("~@/assets/background/p3.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f9e6df;
  height: calc(100vh - 64px);
  width: 100vw;
  min-width: 100vw;
  @media (max-width: 768px) {
    background-image: url("~@/assets/background/p3-p.jpg");
    height: calc(100vh - 31px);
  }
}

.comic-text {
  position: absolute;
  width: 500px;
  height: 250px;
  margin-left: 100px;
  margin-top: 10px;
  font-family: 'Nunito';
  display: flex;
  align-items: center;
  align-self: center;
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    margin-left: 10px;
    margin-top: 0px;
  }
}

.phylactere {
  background-image: url("~@/assets/background/phylactere.png");
  background-position: left;
  background-size: contain;
  width: 700px;
  min-height: 300px;
  left: calc(75vw - 500px);
  margin-top: 10vh;
  position: absolute;
  @media (max-width: 768px) {
    width: calc(100vw - 10px);
    left: 5px;
    background-image: url("~@/assets/background/phylactere-p.png");
  }
}
</style>