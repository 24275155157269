<template>
  <div class="touche-me">
    <canvas id="canvas" ref="canvasParticles" class="canvas">
      <img id="source" src="" width="100vw" height="100vw">
    </canvas>
    <div class="btn-wrapper">
      <span class="mr-sm-4">Une app pour <i class="sexy">sex</i>périmenter</span>
      <button class="white--text pg-hot-gradient pa-4 touche-me-button elevation-12" v-on:mouseup="onClick"
              ref="touchMeButton">
        <i>{{ textVal }}</i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "TouchMeButton",
  data() {
    return {
      initialized: false,
      textVal: " Cliquez-moi...",
      items: [
        {
          text: "et s'explorer",
          src: require('../../assets/background/p2-1.jpg'),
          src_p: require('../../assets/background/p2-1-p.jpg'),
        },
        {
          text: "à son rythme",
          src: require('../../assets/background/p2-2.jpg'),
          src_p: require('../../assets/background/p2-2-p.jpg'),
        },
        {
          text: "sans sexposer",
          src: require('../../assets/background/p2-3.jpg'),
          src_p: require('../../assets/background/p2-3-p.jpg'),
        },
        {
          text: "suivant ses envies",
          src: require('../../assets/background/p2-4.jpg'),
          src_p: require('../../assets/background/p2-4-p.jpg'),
        },
        {
          text: "où l'on veut",
          src: require('../../assets/background/p2-5.jpg'),
          src_p: require('../../assets/background/p2-5-p.jpg'),
        },
      ],
      colors: [
        '78 , 21 , 26',
        '160 , 39 , 57',
        '190 , 117 , 41',
        '216 , 209 , 36'
      ],
      c: null,
      ctx: null,
      mouseX: null,
      mouseY: null,
      txtPosition: 0,
      particles: [],
      idAnimation: undefined
    }
  },
  mounted() {
    this.c = document.getElementById('canvas');
    this.c = this.$refs.canvasParticles;
    this.ctx = this.c.getContext('2d');
    this.c.width = window.innerWidth;
    this.c.height = window.innerHeight;
    this.drawBg();
  },

  methods: {
    initialize() {
      if (!this.initialized) {
        console.log(this.$refs.touchMeButton.getBoundingClientRect());
        console.log(this.$refs.touchMeButton)
        this.initialized = true;
        this.c = document.getElementById('canvas');
        this.c = this.$refs.canvasParticles;
        this.ctx = this.c.getContext('2d');
        this.c.width = window.innerWidth;
        this.c.height = window.innerHeight;
        this.mouseX = this.$refs.touchMeButton.getBoundingClientRect().x;
        this.mouseY = this.$refs.touchMeButton.getBoundingClientRect().y;
        setTimeout(function () {
          this.createParticles();
        }.bind(this), 250);
        this.draw();
      }
    },
    findPos(obj) {
      var curleft = 0;
      var curtop = 0;
      if (obj.offsetParent) {
        do {
          curleft += obj.offsetLeft;
          curtop += obj.offsetTop;
          obj = obj.offsetParent;
        } while (obj);
        return {X: curleft, Y: curtop};
      }
      return null;
    },
    onClick() {
      this.initialize();
      this.createParticles();
      this.changeText();
    },
    draw() {
      this.drawBg();
      this.incParticles();
      this.drawParticles();
      this.idAnimation = window.requestAnimationFrame(this.draw);
    },
    drawBg() {
      this.ctx.fillStyle = "rgb(40, 45, 50)";
      let bgImg = new Image();
      bgImg.onload = function () {
        this.ctx.drawImage(bgImg, 0, 0, this.c.width, this.c.height)
        this.ctx.fill();
      }.bind(this)
      bgImg.src = this.$vuetify.breakpoint.mobile ? this.items[this.txtPosition].src_p : this.items[this.txtPosition].src;
    },
    drawParticles() {
      for (let i = 0; i < this.particles.length; i++) {
        this.ctx.beginPath();
        this.ctx.arc(this.particles[i].x,
            this.particles[i].y,
            this.particles[i].size,
            0,
            Math.PI * 2);
        this.ctx.fillStyle = this.particles[i].color;
        this.ctx.closePath();
        this.ctx.fill();
      }
    },
    incParticles() {
      for (let i = 0; i < this.particles.length; i++) {
        this.particles[i].x += this.particles[i].velX;
        this.particles[i].y += this.particles[i].velY;

        this.particles[i].size = Math.max(0, (this.particles[i].size - .05));
        if (this.particles[i].size === 0) {
          this.particles.splice(i, 1);
        }
      }
    },
    createParticles() {
      for (let i = 0; i < 20; i++) {
        this.particles.push({
          x: this.mouseX,
          y: this.mouseY,
          size: parseInt(Math.random() * 10),
          color: 'rgb(' + this.ranRgb() + ')',
          velX: this.ranVel(),
          velY: this.ranVel()
        });
      }
    },
    ranRgb() {
      let i = parseInt(Math.random() * 3);
      return this.colors[i];
    },
    ranVel() {
      let vel = 0;
      if (Math.random() < 0.5) {
        vel = Math.abs(Math.random());
      } else {
        vel = -Math.abs(Math.random());
      }
      return vel;
    },
    changeText() {
      if (this.items.length - this.txtPosition <= 1) {
        this.txtPosition = 0;
      } else {
        this.txtPosition++;
      }
      this.textVal = this.items[this.txtPosition].text;
    }
  },
  destroyed() {
    window.cancelAnimationFrame(this.idAnimation);
    this.idAnimation = undefined;
  }
}
</script>

<style scoped lang="scss">
i {
  font-weight: normal;
  font-family: 'Nunito';
}

.sexy {
  font-family: Lobster;
  padding-right: 3px;
}

.canvas {
  position: relative;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  min-width: 100vw;
}

.touche-me-button {
  border-radius: 42px;
  -webkit-text-stroke: unset !important;
}

.touche-me {
  font-family: 'Nunito';
  box-sizing: border-box;
  font-weight: bold;
  font-size: 2.5em;
  color: white;
  padding: 0;
  margin: 0;
  position: relative;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  min-width: 100vw;
}

.btn-wrapper {
  position: absolute;
  top: 70vh;
  left: 18vw;
  width: 100%;
  text-align: center;
  z-index: 2;
  @media (max-width: 768px) {
    top: 75vh;
    left: 12vw;
    width: 80vw;
    text-align: right;
    padding-right: 5px;
    font-size: 0.5em;
  }
}

.btn {
  display: inline-block;
  box-shadow: none;
  appearance: none;
  border: 0;
  outline: 0;
  background: linear-gradient(135deg, #54000E, #811F31, #A02838, #A7462F, #B8642A, #BF7527, #C48D27, #C4B427, #D8D225);
  height: 45px;
  line-height: 42px;
  padding: 0 30px;
  font-size: 2.5em;
  border-radius: 30px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: all .5s;
  transition-timing-function: cubic-bezier(.2, 3, .4, 1);
  user-select: none;

  &:hover {
    transform: scale(1.1, 1.1);
  }

  &:active {
    transform: scale(1.05, 1.05);
  }
}
</style>