<template>
  <v-container class="private-background pa-0">
    <v-row align="center" v-if="$vuetify.breakpoint.mobile">
      <v-btn class="ma-auto pg-hot-gradient beta-button" dark
             href="https://app.private-games.com"
             target="_blank">
        Jouer maintenant !
      </v-btn>
    </v-row>
    <v-row no-gutters justify="center">
      <v-btn to="#canvas" @click="goToCanvas" elevation="12"
             class="ma-auto pg-color-very-dark-pink sexperimenter-vous wavetext" dark x-large rounded ref="buttonHome"
             v-html="spannedHtml"
      />
    </v-row>
  </v-container>
</template>

<script>
import NavigationMixin from "../../mixins/NavigationMixin";

export default {
  name: "HelloWorld",
  mixins: [NavigationMixin],
  data: () => ({
    text: "Sexplorez-vous...",
    spannedHtml: "",
    justify: [
      'center',
    ],
  }),
  methods: {
    goToCanvas(){
      this.setCurrentNav('#canvas').then(()=>{
        this.$vuetify.goTo(this.currentNav);
      })
    },
    spanified() {
      for (let i in this.text) {
        if (this.text[i] === " ") {
          this.spannedHtml += "<span>&nbsp;</span>";
        } else {
          this.spannedHtml += "<span>" + this.text[i] + "</span>";
        }
      }
    }
  },
  created() {
    this.spanified();
  }
};
</script>
<style lang="scss">

$offset: -8px;
$count: 10;
$duration: 1.5;
.beta-button{
top: 5em;
}
@keyframes wave {
  from {
    transform: translateY(0);
    color: white;
  }
  to {
    transform: translateY($offset);
  }
}

.wavetext::v-deep {
  margin-top: 10em;
  text-align: center;
}

.wavetext span {
  display: inline-block;
  animation-duration: #{$duration}s;
  animation-name: wave;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@for $i from 0 to $count {
  .wavetext :nth-child( #{$count}n + #{$i} ) {
    animation-delay: -#{($count - $i) * 2 * $duration / $count }s;
  }
}

.sexperimenter-vous {
  font-family: 'Nunito';
  box-sizing: border-box;
  font-weight: bold;
  font-size: 3.0em !important;
  color: white;
  min-width: 38vw !important;
  max-width: 95vw !important;
  min-height: 10vh !important;
  top: 72vh;

  &:hover {
    transform: scale(1.1, 1.1);
  }

  &:active {
    transform: scale(1.05, 1.05);
  }

  @media (max-width: 768px) {
    font-size: 1.5em !important;
  }
}

.private-background {
  background-image: url("~@/assets/background/p1.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f9e6df;
  height: calc(100vh - 64px);
  width: 100vw;
  min-width: 100vw;
  @media (max-width: 768px) {
    background-image: url("~@/assets/background/p1-p.jpg");
    height: calc(100vh - 54px);
  }
}


</style>
