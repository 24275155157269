<template>
  <v-container style="height: 100vh; max-height: 100vh;" class="pa-0" fluid id="sexperimenter">
    <touch-me-button/>
  </v-container>
</template>

<script>
import TouchMeButton from "./TouchMeButton";

export default {
  name: "Sexperimenter",
  components: {TouchMeButton},
  data() {
    return {}
  },
}
</script>

<style scoped>

</style>