<template>
  <v-container style="height: 100vh; max-height: 100vh;" class="pa-0 private-background" fluid>
    <v-card :style="$vuetify.breakpoint.mobile?'top:10vh':'top:25vh; max-width: 60vw'" class="ml-2 ml-sm-5" flat
            color="transparent">
      <v-card-title>
        <h1 class="text-uppercase">1 + 1 = 3</h1>
      </v-card-title>
      <v-card-text>
        <h2 class="mb-6 mt-6">Le couple n’est pas une somme d’individualités</h2>
        <p>La vision de Private-Games est que dans l’exploration, le couple fonctionne comme une entité unique : ce n’est
          pas le plaisir de chacun, c’est le plaisir du couple entier.
        </p>
        <p>Notre vision est donc que les couples doivent avancer ensemble pour mieux se découvrir et que la
          sexpérimentation ne doit pas être égo-centrée.</p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "SumMore"
}
</script>

<style scoped lang="scss">
h1 {
  font-family: "Nunito";
  font-weight: bolder;
  @media (max-width: 758px) {
    font-size: 1.5em;
  }
}

.private-background {
  background-image: url("~@/assets/background/background-pg-xl-white.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f9e6df;
  height: calc(100vh - 128px);
  width: 100vw;
  min-width: 100vw;
  @media (max-width: 768px) {
    background-image: url("~@/assets/background/background-pg-portrait-xl-white.jpg");
  }
}
</style>