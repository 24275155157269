<template>
<div>
  <shared id="home"/>
  <sum-more id="sum-more"/>
  <offer-more id="offer-more"/>
</div>
</template>

<script>
import NavigationMixin from "../mixins/NavigationMixin";
import Shared from "../components/our-vision/Shared";
import SumMore from "../components/our-vision/SumMore";
import OfferMore from "../components/our-vision/OfferMore";

export default {
  name: "OurVision",
  components: {OfferMore, SumMore, Shared},
  mixins:[NavigationMixin],
  mounted() {
    this.setAvaibleNavs(['#home', '#sum-more', '#offer-more']);
  }
}
</script>

<style scoped>

</style>